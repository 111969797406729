<template>
  <div style="height: 100vh">
    <div
      class="h-100 align-items-center justify-content-center d-none d-md-flex"
    >
      <b-card
        style="border-radius: 20px; border: 1px solid lightgrey"
        class="px-1 m-0"
      >
        <div class="mt-1">
          <b-link :to="{ name: 'Login' }">
            <img :src="this.appLogoImage" img-fluid width="200" height="75"
          /></b-link>
        </div>

        <b-card-title
          title-tag="h2"
          class="font-weight-bolder text-colorBlack mb-50 mt-2 pr-5 mr-5"
        >
          Welcome to Success Academy.
        </b-card-title>
        <b-card-text class="text-colorGray" style="font-size: 0.8rem">
          Please Enter your Credentials to access the Portal
        </b-card-text>

        <validation-observer ref="loginFormValidation">
          <b-form class="auth-login-form mt-2" @submit.prevent>
            <b-form-group label-for="fullname">
              <validation-provider
                #default="{ errors }"
                name="Full Name"
                rules="required"
              >
                <b-form-input
                  id="fullname"
                  v-model="fullname"
                  :state="errors.length > 0 ? false : null"
                  name="fullname"
                  placeholder="Full Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label-for="email" class="mt-2">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required"
              >
                <b-form-input
                  id="email"
                  v-model="email"
                  :state="errors.length > 0 ? false : null"
                  name="email"
                  placeholder="Email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label-for="password" class="mt-2">
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="password"
                    placeholder="Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-button
              class="mt-3"
              type="submit"
              variant="colorBlue"
              block
              :to="{ name: 'SignupConfirmation' }"
              @click="validateForm"
            >
              Sign Up
            </b-button>
            <div
              class="text-center font-weight-normal text-colorGray"
              style="font-size: 0.9rem"
            >
              <p class="m-0 py-2">or Sign Up with</p>
              <b-button
                type="submit"
                variant="outline-colorGray"
                block
                @click="validateForm"
                class=""
              >
                Sign Up with Google
              </b-button>
            </div>
            <div class="pt-3 text-center font-weight-bold text-colorBlack">
              <p class="m-0">
                Already have an account?
                <b-link
                  class="font-weight-bolder text-colorBlue"
                  :to="{ name: 'Login' }"
                  >Login</b-link
                >
                instead.
              </p>
            </div>
          </b-form>
        </validation-observer>
      </b-card>
    </div>

    <div class="d-block d-md-none h-100">
      <div style="" class="m-0 h-100">
        <b-row style="height: 10%">
          <b-col md="12" style="border-bottom: 2px solid lightgrey">
            <b-row>
              <b-col sm="12" class="p-0">
                <b-navbar toggleable="lg" class="px-2">
                  <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
                  <b-collapse id="nav-collapse" is-nav>
                    <b-navbar-nav>
                      <b-nav-item href="#">Link</b-nav-item>
                    </b-navbar-nav>

                    <!-- Right aligned nav items -->
                    <b-navbar-nav class="ml-auto">
                      <b-nav-item-dropdown text="Lang" right>
                        <b-dropdown-item href="#">EN</b-dropdown-item>
                        <b-dropdown-item href="#">ES</b-dropdown-item>
                        <b-dropdown-item href="#">RU</b-dropdown-item>
                        <b-dropdown-item href="#">FA</b-dropdown-item>
                      </b-nav-item-dropdown>

                      <b-nav-item-dropdown right>
                        <template #button-content>
                          <em>User</em>
                        </template>
                        <b-dropdown-item href="#">Profile</b-dropdown-item>
                        <b-dropdown-item href="#">Sign Out</b-dropdown-item>
                      </b-nav-item-dropdown>
                    </b-navbar-nav>
                  </b-collapse>
                  <b-navbar-brand href="#">
                    <img
                      :src="this.appLogoImage"
                      img-fluid
                      width="100"
                      height="75"
                  /></b-navbar-brand>
                </b-navbar>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <div class="px-2 py-auto" style="height: 90%">
          <div class="mt-5 pt-2">
            <b-link :to="{ name: 'Login' }">
              <img :src="this.appLogoImage" img-fluid width="200" height="75"
            /></b-link>
          </div>

          <b-card-title
            title-tag="h2"
            class="font-weight-bolder text-colorBlack mb-50 mt-2 pr-5 mr-5"
          >
            Welcome to Success Academy.
          </b-card-title>
          <b-card-text class="text-colorGray" style="font-size: 0.8rem">
            Please Enter your Credentials to access the Portal
          </b-card-text>

          <validation-observer ref="loginFormValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <b-form-group label-for="fullname">
                <validation-provider
                  #default="{ errors }"
                  name="Full Name"
                  rules="required"
                >
                  <b-form-input
                    id="fullname"
                    v-model="fullname"
                    :state="errors.length > 0 ? false : null"
                    name="fullname"
                    placeholder="Full Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label-for="email" class="mt-2">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required"
                >
                  <b-form-input
                    id="email"
                    v-model="email"
                    :state="errors.length > 0 ? false : null"
                    name="email"
                    placeholder="Email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label-for="password" class="mt-2">
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-button
                class="mt-3"
                type="submit"
                variant="colorBlue"
                block
                :to="{ name: 'SignupConfirmation' }"
                @click="validateForm"
              >
                Sign Up
              </b-button>
              <div
                class="text-center font-weight-normal text-colorGray"
                style="font-size: 0.9rem"
              >
                <p class="m-0 py-2">or Sign Up with</p>
                <b-button
                  type="submit"
                  variant="outline-colorGray"
                  block
                  @click="validateForm"
                  class=""
                >
                  Sign Up with Google
                </b-button>
              </div>
              <div class="pt-3 text-center font-weight-bold text-colorBlack">
                <p class="m-0">
                  Already have an account?
                  <b-link
                    class="font-weight-bolder text-colorBlue"
                    :to="{ name: 'Login' }"
                    >Login</b-link
                  >
                  instead.
                </p>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { $themeConfig } from "@themeConfig";
import { mapActions, mapGetters } from "vuex";
import util from "@/util.js";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility, util],
  data() {
    return {
      appName: "",
      appFullName: "",
      appFullName: "",
      password: "",
      fullname: "",
      email: "",
      required,
    };
  },
  created() {
    this.appName = $themeConfig.app.appName;
    this.appFullName = $themeConfig.app.appFullName;
    this.appLogoImage = $themeConfig.app.appLogoImage;
  },
  methods: {
    ...mapActions({ login: "appData/login" }),
    async validateForm() {
      const success = await this.$refs.loginFormValidation.validate();
      if (success) {
        await this.submit();
      }
    },
    async submit() {
      try {
        const res = await this.login({
          username: this.username,
          password: this.password,
        });
        if (res.status === 200) {
          this.$swal({
            title: "Logged in successfully",
            icon: "success",
          });
          this.$router.push({ name: "Candidate" });
        }
      } catch (error) {
        this.displayError(error);
      }
    },
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    ...mapGetters({ hasRole: "appData/hasRole" }),
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>

<style scoped></style>
